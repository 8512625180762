import styled from "styled-components"
import media from "../../theme/breakpoints"

export const StyledDivider = styled.hr`
  margin: 10rem auto;
  border: none;
  background: ${props => props.theme.colors.border};
  height: 1px;
    
  ${media.md`
    margin-top: 5rem;
    margin-bottom: 5rem;
  `}

  ${media.sm`
    margin-top: 3rem;
    margin-bottom: 3rem;
  `}
`
